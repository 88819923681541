@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Cairo:wght@200;300;400;500;600;700&family=Edu+QLD+Beginner:wght@400;500;600;700&family=Lobster&family=Nunito:ital,wght@0,300;0,400;0,500;1,300;1,500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Cairo:wght@200;300;400;500;600;700;800;900&family=Edu+QLD+Beginner:wght@400;500;600;700&family=Lobster&family=Nunito:ital,wght@0,300;0,400;0,500;1,300;1,500&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Cairo', sans-serif;
  
  scroll-behavior: smooth !important;

 
}
html{
  direction: rtl;
  scroll-behavior: smooth !important;
}
a{
  color: black !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
   --color:#dcac34

}
ul{
  list-style: none;
  margin-bottom: 0 !important;
}
a{
  text-decoration: none; 
}
/* swipprer images */
.mySwiper img{
  height: 100vh  !important;
}
.swiper-pagination{
  display: none !important;
}
.swiper-button-next ,.swiper-button-prev{
  display: none !important;
}



/* navbar */
.navba {
  position: fixed;

 
transition: all 0.4s ;
  z-index: 111;
  box-shadow: 0px 0px 10px lightgray;
  
}
.icons-nav{
  position: fixed;
  bottom: 0;
  right: 2%;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  height: 60px;
  /* transform: translateY(100%); */
  transition: all 0.7s;
  z-index: 5000;
  background-color: var(--color) ;
  
}
.navba ul a  {
  width: 50% ;
  font-size: 20px !important;
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  
}
/* .navba ul a li{
   width: 50% !important ;
   font-size: 20px !important;
   font-weight: bolder;
   cursor: pointer;
   display: flex;
   justify-content: center;
   padding: 20px 0px;
} */
/* .navba ul li a{
  width: 100% !important;
} */
.navba img{
  width: 43% !important}
  .first-link{
    background-color: var(--color);
    padding: 20px  30px;
  }
.parents-icons{
  position: fixed;
    left: 1%;
    color: white;
    bottom: 2%;
    z-index: 5000;
    display: flex;
    align-items: center;
    

}
.toggles{
  display: none;
}
.bars i{
  display: none ;

}
.lists-navbars{
  display: flex;
}
.parents-icons1{
  position: fixed;
  left: 1%;
  color: white;
  bottom: 16%;
  z-index: 5000;
  display: flex;
  align-items: center;
}
.calls1{
  background-color: #128C7E;
  padding: 2px 20px;
  border-radius: 10px;
  transform: translateX(-300%);
  transition: all 0.4s;
}

.calls{
  background-color: #4caf50;
  padding: 2px 20px;
  border-radius: 10px;
  transform: translateX(-300%);
  transition: all 0.4s;
}
.parents-icons:hover .calls{
 transform: translateX(4%);

}
.parents-icons1:hover .calls1{
  transform: translateX(4%);
 
 }
 .parents-icons1:hover .whatsapp1{
  background-color: #128C7E;
}
.parents-icons:hover .whatsapp{
  background-color: #4caf50;
}
  .whatsapp ,.whatsapp1{
    width: 60px;
    height: 60px;
    font-size: 25px;
    font-weight: bolder;

    background-color: var(--color);
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

  }


  .navba li:hover{

    color: var(--color);
  }
  .parent-services{
    position: relative;

  }

  .header{
    position: relative;
  }
  .position-header{
    position: absolute;
    top: 70%;
    left: 50%;
     color: white;
     background-color: var(--color);
     box-shadow: 0px 0px 10px var(--color);
     width: 300px  ;
    z-index: 100;
    transform: translate(-50%,-50%);
    

  }
  .position-header h4{
    cursor: pointer;
  }
  .list-services{
    position: absolute;
    top: 207%;
    right: -40%;

  }
  .list-services  ul {
    padding: 10px 0px !important;
    box-shadow: 0px 0px 10px lightgray;
    width: 250px !important;
  }
  .list-services a  {
    width: 80%!important;
    margin-bottom: 5px ;
    margin-top: 4px;
    font-weight: 400 !important;
    padding: 0  16px!important ;
  
   
    justify-content: start !important;
transition: all 0.6s;
font-family: 'Amiri', serif;

  
  }
  .list-services a:hover{
    border-right: 5px solid var(--color) ;
    margin-right: 10px;
  }
  .anothers{
    opacity: 0;
    transition: all 0.8s ;
  }
  /* about */
  .about{
    background-color: #f5f5f5 !important;
  }
  .about h1{
    font-size: 90px;
    margin-bottom: 10px;
    letter-spacing: 10px;
  }
  .about .afters{
    background-color: rgb(180, 179, 179);
    height: 3px;
    width: 100%;
    border-radius: 10px;
    position: relative;
    margin-bottom: 30px;
  }
  .about .afters::after {
    content: "";
    position: absolute;
    width: 10%;
    height: 100%;
    background-color: var(--color);
  }
  .about img{
    box-shadow: 0px 0px 10px lightgray;
  }
  .about p {
    letter-spacing: -1px;
    font-weight: 500;
  }


  /* services */
  .services .content {
    background-color: white;
    border-radius: 10px ;
    box-shadow: 0px 0px 10px lightgray;
    transform: translateY(-20px);
    padding: 0px 15px;
    width: 90%;
    margin: auto;
  }
  
  .parent-points{
    position: relative;
width: fit-content;
margin: auto;

  }
  .points{
    position: absolute;
    left: 0%;
    top: -7px;
    width: 15px;
    height: 15px;
    background-color: black;
  
    transition: all 0.6s !important;
    border-radius: 50%;
   
  }
  .parent-points button{
   
    font-size: 16px;
    color: #ffffff;
    padding: 12px 30px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    border: none;
    border-radius: 8px;
    background: #dcac34;
    box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
font-weight: bolder;
      transform: translate(10px, -2px);
  
  }
  .parent-points:hover .points  { 
    background-color: black;
    width:110% !important;
    height:120%;
    border-radius: 10px;
  }
  .services .items{
    transition: all 0.5s;
   
  }
 .services .items:hover{
  transform: translateY(-10px);
 }
 .services .items p {
  height: 60px;
 }


 /* contact */
 .contact{
  background-image: url("https://drammar-clinics.com/images/contact-bg.jpg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
 }

 .contact button{
  width: 100%;
  margin-top: 20px;
  border: none;
  background-color: var(--color);
  padding: 20px 0px;
  color: white;
  font-weight: 900;
 }
 .contact .icons{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 6px solid  var(--color) ;
  background: white  ;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color);
  font-size: 30px;
 }


 /* compresion */
 /* .compresion .data img{
  overflow: hidden;
  transition: all 0.9s;
 } */
 .compresion .items{
  overflow: hidden;
 position: relative;

 }

 .position-comprasion{
  position: absolute;
  top: 10%;
  left: 3%;
  right: 3%;
  bottom: 10%;
/* padding: 40px; */


 }
 .compresion .items img {
  transition: all 0.7s;
  scale: 1.3;
 }
 .compresion .items:hover img{
   scale: 1;

 
 }
 .compresion .items:hover .position-comprasion{
  border: 2px solid lightgray !important;
 }



 /* news */
 .news{
  background-image: url("https://drammar-clinics.com/images/dot-bg.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
 
 }
 .SRLElementWrapper {
  margin-top: 160px !important;
 }
 .news .items{
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 0px 10px lightgray;
  transition: all 0.7s;
}

.news button{
  padding: 6px 25px;
  border-radius: 10px;
  border: 2px solid #dcac34;
  color: #000;
  font-weight: 500;

}
.news .items:hover{
  transform: translateY(-15px);
}

.news button:hover{
  color: var(--color) !important;
}


/* footer */
.footer{
  background-color: #272727;
  color: white;
}
.footer i {
  color: var(--color);
}
.footer hr{
  background-color: var(--color) !important;
  color: var(--color) !important;
  height: 5px !important;
  width: 100% !important;
}

.footer h4{
  position: relative;
}
.footer h4::after{
  position: absolute;
  content: "";
  width: 10%;
  height: 4px;
  background-color:var(--color);
  top: 140%;
  right: 0;
}
.footer li {
  cursor: pointer;
}



/* information */

.parent-types{
  background-color: #ccc;
  border-radius: 10px;
}
.types{
  background-color:#272727;
  color: white;
  border-radius: 15px;
  width: 80%;
  border: 2px solid var(--color);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.8s;
 
}
.types:hover{
  box-shadow: 0px 0px 10px #ffb700;
   transform: scale(1.1);

}
.types h4{
  font-weight: 300 !important;
  font-size: 20px ;
}
.parent-types button{
  width: 100%;
  margin-top: 20px;
  border: none;
  background-color: var(--color);
  padding: 20px 0px;
  color: white;
  font-weight: 900;

}

.about-pages{
  background-image:url("https://drammar-clinics.com/images/banner/banner-2.jpg");
background-attachment: fixed;
  height:60vh;
  background-position: center;
  background-size: 100% 100%;


}
.content-about-data{
  width: 100%;
  height: 100%;
  background-color: rgba(4, 4, 24,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-about-data h2{
  text-align: center;
   width: 40%;
   line-height: 2;
   font-weight: bolder;
   font-size: 40px;
   color: white;
}


/* circles */
.parent-circles{
   
  position: relative;
 bottom: 7%;
   

}
.images-circle{
  width: 50px ;
  height: 50px;
  position: absolute;
  z-index: 2;
  border-radius: 50%;

}
.circles{
z-index: 1;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
   animation:  circles 2s infinite cubic-bezier(.36,.11,.89,.32);
   position: absolute;
   opacity: 0;
}

@keyframes circles
 { 
   from {opacity: 0.5;  transform:scale(0.5,0.5) ;}
   to{opacity: 0;  transform:scale(2.5,2.5) }

  
}

.works .items {
  position: relative;
  padding: 19px;
}

.works .items img{
  transition: all 0.8s;
  overflow: hidden;

}
.works .items::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 40%;
  top: 0px;
  left:0px;
  right: 0px;
  bottom: 0;
  border: 2px solid var(--color);
  border-left: none;
  border-bottom: none;
  /* border: 10px solid blue; */
}
.works .items:hover img{
  scale: 1.1;
}


/* large */
@media (max-width:1200px) and (min-width:992px){

 .navba .bars i  {
    color: var(--color) !important;
    cursor: pointer;
  }


  .toggles ,.yara,.sara{
    position: fixed;

    bottom: 0;
    background-color: white;
   top: 0%;
    z-index: 6000 !important;
    box-shadow: 0px 0px 10px lightgray;
    direction: ltr;
    transition: all 1s;
  }
   .sara{
    left: -100%;
   }
   .yara{
    left: 0;
   }









  .toggles1{
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: white;
   top: 0%;
    z-index: 6000 !important;
    box-shadow: 0px 0px 10px lightgray;
    overflow: scroll;
  direction: ltr;

  }

  
  .navba {
     position: fixed;
    left: 0% !important;
    right: 0% !important;
    top: 0% !important;
    z-index: 8000 !important;
}


.toggles ul ,.toggles1 ul {
  width: 300px !important;
  padding: 30px 0px  !important;
}
.toggles ul a ,.toggles1 ul a{
  /* margin-bottom: 30px; */
  font-weight: bolder;
  width: 100% !important;
  padding: 20px 0px;

  text-align: center;
  transition: all 0.2s;
}
 .toggles .list-dervices1 ul a li ,.toggles1 .list-dervices1 ul a li  {
  width: 100% !important;
  padding: 20px 0px;


  text-align: center;
}
.toggles .exta-lists  , .toggles1 .exta-lists {
  padding: 0 !important;
}
.toggles .exta-lists a li , .toggles1 .exta-lists a li  {
  font-weight: lighter;
  padding: 20px 0px;


}
.toggles .list-dervices ,.toggles1 .list-dervices{
  height: 0;
  overflow: hidden;
  opacity: 0;

}
 .toggles .list-dervices1 , .toggles1 .list-dervices1{
  opacity: 1;
  height: 1000px ;
  transition: all  2s;
  margin-bottom: 0 !important;

}


.toggles ul a:hover , .toggles1 ul a:hover {
  background-color: var(--color) ;
  color: white !important;
}
 .list-dervices1 a li:hover{
  color: white !important;
  background-color: var(--color);
  
}
.list-dervices1 a 
.exta-lists{
  padding: 0 !important;
}

.lists-navbars{
  display: none !important;
}
.bars i{
  display: flex;
}
.toggles{
  display: flex;
}



/* about */


.about-pages {
  
    height: 80vh;
  
}

.content-about-data h2 {
  width: 70%;

}



}
/* meduin */
@media (max-width:992px) and (min-width:768px)

{


  .navba .bars i  {
    color: var(--color) !important;
    cursor: pointer;
  }


  .toggles ,.yara,.sara{
    position: fixed;

    bottom: 0;
    background-color: white;
   top: 0%;
    z-index: 6000 !important;
    box-shadow: 0px 0px 10px lightgray;
    direction: ltr;
    transition: all 1s;
  }
   .sara{
    left: -100%;
   }
   .yara{
    left: 0;
   }









  .toggles1{
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: white;
   top: 0%;
    z-index: 6000 !important;
    box-shadow: 0px 0px 10px lightgray;
    overflow: scroll;
  direction: ltr;

  }

  
  .navba {
     position: fixed;
    left: 0% !important;
    right: 0% !important;
    top: 0% !important;
    z-index: 8000 !important;
}


.toggles ul ,.toggles1 ul {
  width: 300px !important;
  padding: 30px 0px  !important;
}
.toggles ul a ,.toggles1 ul a{
  /* margin-bottom: 30px; */
  font-weight: bolder;
  width: 100% !important;
  padding: 20px 0px;

  text-align: center;
  transition: all 0.2s;
}
 .toggles .list-dervices1 ul a li ,.toggles1 .list-dervices1 ul a li  {
  width: 100% !important;
  padding: 20px 0px;


  text-align: center;
}
.toggles .exta-lists  , .toggles1 .exta-lists {
  padding: 0 !important;
}
.toggles .exta-lists a li , .toggles1 .exta-lists a li  {
  font-weight: lighter;
  padding: 20px 0px;


}
.toggles .list-dervices ,.toggles1 .list-dervices{
  height: 0;
  overflow: hidden;
  opacity: 0;

}
 .toggles .list-dervices1 , .toggles1 .list-dervices1{
  opacity: 1;
  height: 1000px ;
  transition: all  2s;
  margin-bottom: 0 !important;

}


.toggles ul a:hover , .toggles1 ul a:hover {
  background-color: var(--color) ;
  color: white !important;
}
 .list-dervices1 a li:hover{
  color: white !important;
  background-color: var(--color);
  
}
.list-dervices1 a 
.exta-lists{
  padding: 0 !important;
}

.lists-navbars{
  display: none !important;
}
.bars i{
  display: flex;
}
.toggles{
  display: flex;
}



/* about */


.about-pages {
  
    height: 80vh;
  
}

.content-about-data h2 {
  width: 70%;

}




.navba img {
  width: 44% !important;
}

.contact .icons {
  width: 50px;
  height: 50px;
  
}
.contact .icons i{
  font-size: 21px !important;
}
  
}


/* small */
@media (max-width:768px) and (min-width:576px)

  {


    .navba .bars i  {
      color: var(--color) !important;
      cursor: pointer;
    }
  
  
    .toggles ,.yara,.sara{
      position: fixed;
  
      bottom: 0;
      background-color: white;
     top: 0%;
      z-index: 6000 !important;
      box-shadow: 0px 0px 10px lightgray;
      direction: ltr;
      transition: all 1s;
    }
     .sara{
      left: -100%;
     }
     .yara{
      left: 0;
     }
  
  
  
  
  
  
  
  
  
    .toggles1{
      position: fixed;
      left: 0;
      bottom: 0;
      background-color: white;
     top: 0%;
      z-index: 6000 !important;
      box-shadow: 0px 0px 10px lightgray;
      overflow: scroll;
    direction: ltr;
  
    }
  
    
    .navba {
       position: fixed;
      left: 0% !important;
      right: 0% !important;
      top: 0% !important;
      z-index: 8000 !important;
  }
  
  
  .toggles ul ,.toggles1 ul {
    width: 300px !important;
    padding: 30px 0px  !important;
  }
  .toggles ul a ,.toggles1 ul a{
    /* margin-bottom: 30px; */
    font-weight: bolder;
    width: 100% !important;
    padding: 20px 0px;
  
    text-align: center;
    transition: all 0.2s;
  }
   .toggles .list-dervices1 ul a li ,.toggles1 .list-dervices1 ul a li  {
    width: 100% !important;
    padding: 20px 0px;
  
  
    text-align: center;
  }
  .toggles .exta-lists  , .toggles1 .exta-lists {
    padding: 0 !important;
  }
  .toggles .exta-lists a li , .toggles1 .exta-lists a li  {
    font-weight: lighter;
    padding: 20px 0px;
  
  
  }
  .toggles .list-dervices ,.toggles1 .list-dervices{
    height: 0;
    overflow: hidden;
    opacity: 0;
  
  }
   .toggles .list-dervices1 , .toggles1 .list-dervices1{
    opacity: 1;
    height: 1000px ;
    transition: all  2s;
    margin-bottom: 0 !important;
  
  }
  
  
  .toggles ul a:hover , .toggles1 ul a:hover {
    background-color: var(--color) ;
    color: white !important;
  }
   .list-dervices1 a li:hover{
    color: white !important;
    background-color: var(--color);
    
  }
  .list-dervices1 a 
  .exta-lists{
    padding: 0 !important;
  }
  
  .lists-navbars{
    display: none !important;
  }
  .bars i{
    display: flex;
  }
  .toggles{
    display: flex;
  }
  
  
  
  /* about */
  
  
  .about-pages {
    
      height: 80vh;
    
  }
  
  .content-about-data h2 {
    width: 70%;
  
  }
  
  
  
  
  .navba img {
    width: 56% !important;
  }
  
  .contact .icons {
    width: 50px;
    height: 50px;
    
  }
  .contact .icons i{
    font-size: 21px !important;
  }
  
}

/* extra small */
@media(max-width:576px) {
  


    .navba .bars i  {
      color: var(--color) !important;
      cursor: pointer;
    }
  
  
    .toggles ,.yara,.sara{
      position: fixed;
  
      bottom: 0;
      background-color: white;
     top: 0%;
      z-index: 6000 !important;
      box-shadow: 0px 0px 10px lightgray;
      direction: ltr;
      transition: all 1s;
    }
     .sara{
      left: -160% !important;
     }
     .yara{
      left: 0;
     }
  
  
  
  
  
  
  
  
  
    .toggles1{
      position: fixed;
      left: 0;
      bottom: 0;
      background-color: white;
     top: 0%;
      z-index: 6000 !important;
      box-shadow: 0px 0px 10px lightgray;
      overflow: scroll;
    direction: ltr;
  
    }
  
    
    .navba {
       position: fixed;
      left: 0% !important;
      right: 0% !important;
      top: 0% !important;
      z-index: 8000 !important;
  }
  
  
  .toggles ul ,.toggles1 ul {
    width: 375px  !important;
    padding: 30px 0px  !important;
 
  }
  .toggles ul a ,.toggles1 ul a{
    /* margin-bottom: 30px; */
    font-weight: bolder;
    width: 100% !important;
    margin: auto !;
    padding: 20px 0px;
    display: flex;
    justify-content: center !important;

    text-align: center;
    transition: all 0.2s;
  }
   .toggles .list-dervices1 ul a li ,.toggles1 .list-dervices1 ul a li  {
    width: 100% !important;
    padding: 20px 0px;
  
  
    text-align: center;
  }
  .toggles .exta-lists  , .toggles1 .exta-lists {
    padding: 0 !important;
  }
  .toggles .exta-lists a li , .toggles1 .exta-lists a li  {
    font-weight: lighter;
    padding: 20px 0px;
  
  
  }
  .toggles .list-dervices ,.toggles1 .list-dervices{
    height: 0;
    overflow: hidden;
    opacity: 0;
  
  }
   .toggles .list-dervices1 , .toggles1 .list-dervices1{
    opacity: 1;
    height: 1000px ;
    transition: all  2s;
    margin-bottom: 0 !important;
  
  }
  
  
  .toggles ul a:hover , .toggles1 ul a:hover {
    background-color: var(--color) ;
    color: white !important;
  }
   .list-dervices1 a li:hover{
    color: white !important;
    background-color: var(--color);
    
  }
  .list-dervices1 a 
  .exta-lists{
    padding: 0 !important;
  }
  
  .lists-navbars{
    display: none !important;
  }
  .bars i{
    display: flex;
  }
  .toggles{
    display: flex;
  }
  
  
  
  /* about */
  
  
  .about-pages {
    
      height: 80vh;
    
  }
  
  .content-about-data h2 {
    width: 70%;
  
  }
  
  
  
  
  .navba img {
    width: 114% !important;
    margin-right: 36px;
  }
  
  .contact .icons {
    width: 50px;
    height: 50px;
    
  }
  .contact .icons i{
    font-size: 21px !important;
  }
  
  .mySwiper img{
    height: 71vh   !important;
  }
  .parents-icons{
    bottom: 5%;
  }
  
}